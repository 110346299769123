import Vue from 'vue';

import TokenInfoMenu from './TokenInfoMenu.vue';
import CircularLoading from './CircularLoading.vue';
import EnabledDisabled from './EnabledDisabled.vue';
import LateralDialog from './LateralDialog.vue';
import ConfirmDialog from './ConfirmDialog.vue';
import JiraInfoTooltip from './JiraInfoTooltip.vue';

Vue.component('TokenInfoMenu', TokenInfoMenu);
Vue.component('CircularLoading', CircularLoading);
Vue.component('EnabledDisabled', EnabledDisabled);
Vue.component('LateralDialog', LateralDialog);
Vue.component('ConfirmDialog', ConfirmDialog);
Vue.component('JiraInfoTooltip', JiraInfoTooltip);
