import store from '@/store';
import { CLEAR_AUTH_INFO } from '@/store/auth/actions.type';

const AUTHORIZATION_HEADER = 'Authorization';

const authInterceptor = (config) => {
  /* eslint-disable no-param-reassign */
  const { tokenHeader } = store.getters;

  if (!config.headers[AUTHORIZATION_HEADER] && tokenHeader) {
    config.headers[AUTHORIZATION_HEADER] = tokenHeader;
  }

  return config;
};

function redirectToLogin() {
  window.location = `${window.location.origin}/login`;
}

function clearToken() {
  store.dispatch(CLEAR_AUTH_INFO);
}

const responseAuthInterceptor = (config) => {
  const { response } = config;
  if (response.status === 401) {
    clearToken();
    redirectToLogin();
  }
};

export {
  authInterceptor,
  responseAuthInterceptor,
};
