<template>
  <v-row>
    <v-col cols="12" sm="4">
      <home-card-button title="Applications"
                        to="/applications"
                        @click="goToApplications"/>
    </v-col>
    <v-col cols="12" sm="4">
      <home-card-button title="Tags"
                        to="/tags"
                        @click="goToTags"/>
    </v-col>
  </v-row>
</template>

<script>
import { mapGetters } from 'vuex';
import HomeCardButton from './HomeCardButton.vue';
import ApplicationService from '../applications/applications-service';

export default {
  computed: {
    ...mapGetters(['token']),
  },
  methods: {
    goToApplications() {},
    goToTags() {},
  },
  components: {
    HomeCardButton,
  },
  created() {
    ApplicationService.getApplications();
  },
};
</script>
