<template>
  <v-dialog
    scrollable
    hide-overlay
    no-click-animation
    v-model="localValue"
    v-bind="$attrs"
    :width="width"
    :transition="transitionAnimation"
    content-class="lateral-dialog"
  >
    <template v-slot:activator="{ on }">
      <slot name="activator" v-bind:on="on">
      </slot>
    </template>

    <v-card tile height="100%">
      <v-card-title
        primary-title
      >
        <slot name="title"/>
        <v-spacer />
        <v-btn large icon @click="close">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>

      <v-card-text>
        <slot/>
      </v-card-text>
      <v-card-actions>
        <slot name="actions"/>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: {
    value: Boolean,
    width: {
      type: [Number, String],
      default: 550,
    },
    transitionAnimation: {
      type: String,
      default: 'slidel',
    },
  },
  computed: {
    localValue: {
      get() { return this.value; },
      set(localValue) { this.$emit('input', localValue); },
    },
  },
  methods: {
    close() {
      this.localValue = false;
    },
  },
};
</script>

<style>
.v-dialog.lateral-dialog {
  border-radius: 0;
  margin: 0;
  height: 100%;
  position: absolute;
  overflow-y: auto;
  top: 0;
  bottom: 0;
  right: 0;
  min-height: 100%;
}

.slidel-leave-active, .slidel-enter-active {
  transition: .4s;
  transition-timing-function: ease-in-out;
}

.slidel-enter {
  transform: translate(100%, 0);
}

.slidel-leave-to {
  transform: translate(100%, 0);
}
</style>
