<template>
  <v-row class="mx-1">
    <tag-registration @change="onClickRefresh">
      <template v-slot:activator="{ on }">
        <v-btn color="primary" v-on="on" >
          <v-icon>mdi-plus</v-icon>
          Tag
        </v-btn>
      </template>
    </tag-registration>
    <v-spacer/>
    <v-btn icon @click="onClickRefresh">
      <v-icon left>
        mdi-refresh
      </v-icon>
    </v-btn>
    <v-text-field
      dense
      outlined
      clearable
      v-model="localValue"
      class="search-field"
      label="Search"
    />
  </v-row>
</template>

<script>
import TagRegistration from './TagRegistration.vue';

export default {
  props: {
    value: String,
  },
  computed: {
    localValue: {
      get() {
        return this.value;
      },
      set(localValue) {
        this.$emit('input', localValue);
      },
    },
  },
  methods: {
    onClickRefresh() {
      this.$emit('refresh');
    },
  },
  components: {
    TagRegistration,
  },
};
</script>
