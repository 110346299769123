<template>
  <nav>
    <v-app-bar dark
               app
               color="primary">
      <img class="header-logo" src="../../assets/icon1.png"
           alt="Logo"/>
      <v-toolbar-title class="ml-3">
        <router-link to="/home" class="application-link">
          <strong>
            Monitoramento
          </strong>
        </router-link>
      </v-toolbar-title>

      <div class="ml-10">
        <v-btn text link to="/applications" class="mx-3">
          Applications
        </v-btn>
        <v-btn text link to="/tags" class="mx-3">
          Tags
        </v-btn>
      </div>

      <v-spacer/>

      <token-info-menu/>

    </v-app-bar>
  </nav>
</template>

<script>
export default {
  computed: {
    isSmall() {
      return this.$vuetify.breakpoint.smAndDown;
    },
  },
};
</script>

<style scoped>
  .header-logo {
    max-width: 50px;
  }
</style>
