import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';
import colors from 'vuetify/lib/util/colors';
import {
  VSnackbar, VBtn, VIcon,
} from 'vuetify/lib';

Vue.use(Vuetify, {
  components: {
    VSnackbar,
    VBtn,
    VIcon,
  },
});

export default new Vuetify({
  theme: {
    themes: {
      light: {
        primary: colors.blue.darken1,
        secondary: colors.amber.darken1,
        accent: colors.orange.darken3,
      },
    },
  },
});
