import Vue from 'vue';
import VuetifyToast from 'vuetify-toast-snackbar';

Vue.use(VuetifyToast, {
  x: null,
  y: 'bootom',
  timeout: 8000,
  color: null,
  multiLine: false,
  vertical: false,
});
