<template>
  <v-chip label small :color="color">{{ description }}</v-chip>
</template>

<script>
export default {
  props: {
    enabled: [Boolean, String],
  },
  computed: {
    color() {
      if (this.enabled === 'false') {
        return 'error';
      }
      return this.enabled ? 'success' : 'error';
    },
    description() {
      if (this.enabled === 'false') {
        return 'False';
      }
      return this.enabled ? 'True' : 'False';
    },
  },
};
</script>
