import VueCookies from 'vue-cookies';
import { COOKIES_NAME } from './tokens.constants';
import {
  SET_AUTH_TOKENS, INITIALIZE_FROM_COOKIES, CLEAR_TOKENS,
} from './mutations.type';

export default {
  [SET_AUTH_TOKENS](state, token) {
    state.token = token;
    VueCookies.set(COOKIES_NAME, token);
  },
  [INITIALIZE_FROM_COOKIES](state) {
    if (state.token) {
      return;
    }
    state.token = VueCookies.get(COOKIES_NAME);
  },
  [CLEAR_TOKENS](state) {
    state.token = null;
    VueCookies.remove(COOKIES_NAME, null);
  },
};
