import axios from 'axios';

const getApplications = (params) => axios.get('/applications', {
  params,
});

const postApplication = (data) => axios.post('/applications', data);

const deleteApplication = (data) => axios.delete('/applications', {
  data,
});

export default {
  getApplications,
  postApplication,
  deleteApplication,
};
