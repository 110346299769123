<template>
  <div class="text-center">
    <v-menu
      v-model="menuOpened"
      :close-on-content-click="false"
      min-width="250"
      transition="scale-transition"
      offset-x
      offset-y
    >
      <template v-slot:activator="{ on }">
        <v-btn text v-on="on">
          {{ token }}
          <v-icon>mdi-chevron-down</v-icon>
        </v-btn>
      </template>
      <v-card>
        <v-list dense class="pb-0 pt-0">
          <v-list-item @click="onClickLogout">
            <v-list-item-icon>
              <v-icon>mdi-logout</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              Sair
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-card>
    </v-menu>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { LOGOUT } from '../store/auth/actions.type';

export default {
  data() {
    return {
      menuOpened: false,
    };
  },
  methods: {
    ...mapActions({
      logout: LOGOUT,
    }),
    onClickLogout() {
      this.logout();
      window.location = `${window.location.origin}/login`;
    },
  },
  computed: {
    ...mapGetters(['token']),
  },
};
</script>
