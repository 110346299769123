<template>
  <div>
    <v-data-table
      :headers="headers"
      :items="items"
      item-key="name"
      class="elevation-1"
      :search="search"
    >
      <template v-slot:item.url="{ item }">
        <a :href="item.url" target="_blank">
          {{ item.url }}
        </a>
      </template>
      <template v-slot:item.monitoring="{ item }">
        <enabled-disabled :enabled="item.monitoring"/>
      </template>
      <template v-slot:item.options="{ item }">
        <application-registration @change="emitChange" :current-application="item">
          <template v-slot:activator="{ on }">
            <v-btn icon v-on="on" >
              <v-icon>mdi-pencil</v-icon>
            </v-btn>
          </template>
        </application-registration>
        <v-btn icon @click="onClickDelete(item)">
          <v-icon>mdi-delete</v-icon>
        </v-btn>
      </template>
    </v-data-table>
    <confirm-dialog ref="confirm"/>
  </div>
</template>

<script>
import ApplicationRegistration from './ApplicationRegistration.vue';
import ApplicationsService from './applications-service';

export default {
  props: {
    items: Array,
    search: String,
  },
  data() {
    return {
      headers: [
        {
          text: 'Namespace',
          value: 'namespace',
        },
        {
          text: 'Startup',
          value: 'startup',
        },
        {
          text: 'Product',
          value: 'product',
        },
        {
          text: 'URL',
          value: 'url',
        },
        {
          text: 'Monitoring',
          value: 'monitoring',
        },
        {
          text: '',
          value: 'options',
          sortable: false,
        },
      ],
    };
  },
  methods: {
    remove(item) {
      ApplicationsService.deleteApplication({
        url: item.url,
      })
        .then(() => {
          this.$toast('Application removed', { color: 'success' });
          this.emitChange();
        });
    },
    onClickDelete(item) {
      this.$refs.confirm.open('Você deseja remover a application?',
        null,
        { width: 450 })
        .then((confirm) => {
          if (!confirm) {
            return;
          }
          this.remove(item);
        });
    },
    emitChange() {
      this.$emit('change');
    },
  },
  components: {
    ApplicationRegistration,
  },
};
</script>
