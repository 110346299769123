import TokenLogin from '@/modules/login/TokenLogin.vue';
import store from '@/store';
import { INITIALIZE_FROM_COOKIES_AC } from '@/store/auth/actions.type';

const beforeEnterLogin = (to, from, next) => {
  if (!store.getters.isAuthenticated) {
    if (store.getters.tokenFromStorage) {
      store.dispatch(INITIALIZE_FROM_COOKIES_AC);
      return next('/home');
    }
    return next();
  }
  return next('/home');
};

export default {
  path: '/login',
  name: 'login',
  component: TokenLogin,
  beforeEnter: beforeEnterLogin,
};
