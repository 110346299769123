import axios from 'axios';
import ErrorInterceptor from './axios-error-intecerptor';

const axiosInstance = axios.create({
  baseURL: process.env.VUE_APP_API_URL,
});

axiosInstance.interceptors.response.use(null, ErrorInterceptor);

export default axiosInstance;
