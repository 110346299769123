import {
  SET_AUTH_INFO, CLEAR_AUTH_INFO, INITIALIZE_FROM_COOKIES_AC, LOGOUT,
} from './actions.type';
import {
  SET_AUTH_TOKENS, CLEAR_TOKENS, INITIALIZE_FROM_COOKIES,
} from './mutations.type';

export default {
  [SET_AUTH_INFO](context, data) {
    context.commit(SET_AUTH_TOKENS, data);
  },
  [CLEAR_AUTH_INFO](context) {
    context.commit(CLEAR_TOKENS);
  },
  [INITIALIZE_FROM_COOKIES_AC](context) {
    context.commit(INITIALIZE_FROM_COOKIES);
  },
  [LOGOUT](context) {
    context.commit(CLEAR_TOKENS);
  },
};
