<template>
  <lateral-dialog transition="fadet"
                    width="500"
                    v-model="dialog">
    <template v-slot:activator="{ on }">
      <slot name="activator" v-bind:on="on">
      </slot>
    </template>
    <template v-slot:title>
      Application
    </template>
    <template>
      <div class="text--primary mt-3">
        <v-row>
          <v-col cols="12">
            <v-text-field
              outlined
              hide-details
              class="required"
              autocomplete="off"
              label="URL (key)"
              :disabled="editing"
              v-model="application.url"
              :error="$v.application.url.$error"
              @blur="$v.application.url.$touch()"
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <v-text-field
              outlined
              hide-details
              class="required"
              autocomplete="off"
              label="Namespace"
              v-model="application.namespace"
              :error="$v.application.namespace.$error"
              @blur="$v.application.namespace.$touch()"
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <v-text-field
              outlined
              hide-details
              class="required"
              autocomplete="off"
              label="Startup"
              v-model="application.startup"
              :error="$v.application.startup.$error"
              @blur="$v.application.startup.$touch()"
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <v-text-field
              outlined
              hide-details
              class="required"
              autocomplete="off"
              label="Product"
              v-model="application.product"
              :error="$v.application.product.$error"
              @blur="$v.application.product.$touch()"
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <v-switch
              hide-details
              v-model="application.monitoring"
              label="Monitoring"
            ></v-switch>
          </v-col>
        </v-row>
      </div>
    </template>
    <template v-slot:actions>
      <v-btn color="primary"
             :loading="loading"
             @click="save">
        Save
      </v-btn>
      <v-btn @click="close">
        Close
      </v-btn>
    </template>
  </lateral-dialog>
</template>

<script>
import { required } from 'vuelidate/lib/validators';
import ApplicationsService from './applications-service';

export default {
  props: {
    currentApplication: Object,
  },
  data() {
    return {
      dialog: false,
      loading: false,
      editing: false,
      application: {
        namespace: null,
        startup: null,
        product: null,
        monitoring: true,
        url: null,
      },
    };
  },
  methods: {
    close() {
      this.dialog = false;
    },
    isInvalid() {
      this.$v.$touch();
      return this.$v.$invalid;
    },
    saveApplication() {
      this.loading = true;
      ApplicationsService.postApplication(this.application)
        .then(() => {
          this.$toast('Application saved', { color: 'success' });
          this.close();
          this.$emit('change');
        })
        .finally(() => {
          this.loading = false;
        });
    },
    save() {
      if (this.isInvalid()) {
        return;
      }
      this.saveApplication();
    },
    buildInitialState() {
      this.application = {
        namespace: null,
        startup: null,
        product: null,
        monitoring: true,
        url: null,
      };
      this.$v.$reset();
    },
  },
  watch: {
    dialog(newValue, oldValue) {
      if (newValue === oldValue) {
        return;
      }
      if (this.dialog) {
        this.buildInitialState();
      }
      if (this.editing) {
        this.application = { ...this.currentApplication };
      }
    },
  },
  created() {
    this.editing = Boolean(this.currentApplication);
  },
  validations: {
    application: {
      namespace: {
        required,
      },
      startup: {
        required,
      },
      product: {
        required,
      },
      url: {
        required,
      },
    },
  },
};
</script>
