var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-data-table',{staticClass:"elevation-1",attrs:{"show-expand":"","headers":_vm.headers,"items":_vm.items,"item-key":"nome","search":_vm.search},scopedSlots:_vm._u([{key:"header.jira",fn:function(ref){return [_vm._v(" Jira "),_c('jira-info-tooltip')]}},{key:"item.jira",fn:function(ref){
var item = ref.item;
return [(item.sistema_jira)?[_vm._v(" "+_vm._s(item.sistema_jira.value)+" ("+_vm._s(item.sistema_jira.id)+") ")]:_vm._e()]}},{key:"item.enabled",fn:function(ref){
var item = ref.item;
return [_c('enabled-disabled',{attrs:{"enabled":item.enabled}})]}},{key:"item.options",fn:function(ref){
var item = ref.item;
return [_c('tag-registration',{attrs:{"current-tag":item},on:{"change":_vm.emitChange},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":""}},on),[_c('v-icon',[_vm._v("mdi-pencil")])],1)]}}],null,true)}),_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){return _vm.onClickDelete(item)}}},[_c('v-icon',[_vm._v("mdi-delete")])],1)]}},{key:"expanded-item",fn:function(ref){
var headers = ref.headers;
var item = ref.item;
return [_c('td',{attrs:{"colspan":headers.length}},[_c('tags-table-item-details',{staticClass:"mt-3 mb-3",attrs:{"item":item}})],1)]}}])}),_c('confirm-dialog',{ref:"confirm"})],1)}
var staticRenderFns = []

export { render, staticRenderFns }