import axios from '../../plugins/axios/axios-login';

// para verificar o token, tenta fazer a busca de applications
const getApplications = (token, params) => axios.get('/applications', {
  params,
  headers: {
    Authorization: token,
  },
});

export default {
  getApplications,
};
