<template>
  <div>
    <v-row dense>
      <v-col cols="12" sm="6">
        <span class="caption font-weight-bold">Dependencies</span>
        <div v-for="dependency in item.dependencias" :key="dependency">
          {{ dependency }}
        </div>
      </v-col>
      <v-col cols="12" sm="6">
        <span class="caption font-weight-bold">Resources</span>
        <div v-for="resource in item.recursos" :key="resource">
          {{ resource }}
        </div>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  props: {
    item: Object,
  },
};
</script>
