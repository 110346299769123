<template>
  <v-app>
    <router-view name="header"></router-view>
    <!--    style="background-color: #ebedf0"-->
    <v-main style="background-color: #ebedf0">
      <router-view></router-view>
    </v-main>
    <!--    <router-view name="footer"></router-view>-->
  </v-app>
</template>

<script>
export default {
  name: 'App',
  components: {},
};
</script>
