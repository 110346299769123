<template>
  <div>
    <div class="px-5">
      <v-row>
        <v-col cols="12">
          <span class="display-1">Tags</span>
        </v-col>
      </v-row>
    </div>
    <div class="mt-10">
      <tags-header v-model="searchValue" @refresh="refresh"/>

      <div v-if="loading">
        <circular-loading/>
      </div>

      <tags-table v-if="!loading && tags"
                          :search="searchValue"
                          :items="tags"
                          @change="refresh"/>
    </div>
  </div>
</template>

<script>
import { mapToList } from '../../utils/array-utils';
import TagsService from './tags-service';
import TagsTable from './TagsTable.vue';
import TagsHeader from './TagsHeader.vue';

export default {
  data() {
    return {
      loading: false,
      searchValue: null,
      tags: null,
    };
  },
  methods: {
    refresh() {
      this.getTags();
    },
    getTags() {
      this.loading = true;
      TagsService.getTags()
        .then(({ data }) => {
          this.tags = mapToList(data);
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
  created() {
    this.getTags();
  },
  components: {
    TagsTable,
    TagsHeader,
  },
};
</script>
