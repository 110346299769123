<template>
  <div>
    <v-data-table
      show-expand
      :headers="headers"
      :items="items"
      item-key="nome"
      class="elevation-1"
      :search="search"
    >
      <template v-slot:header.jira="{ }">
        Jira
        <jira-info-tooltip/>
      </template>
      <template v-slot:item.jira="{ item }">
        <template v-if="item.sistema_jira">
          {{ item.sistema_jira.value }} ({{ item.sistema_jira.id }})
        </template>
      </template>
      <template v-slot:item.enabled="{ item }">
        <enabled-disabled :enabled="item.enabled"/>
      </template>
      <template v-slot:item.options="{ item }">
        <tag-registration @change="emitChange" :current-tag="item">
          <template v-slot:activator="{ on }">
            <v-btn icon v-on="on" >
              <v-icon>mdi-pencil</v-icon>
            </v-btn>
          </template>
        </tag-registration>
        <v-btn icon @click="onClickDelete(item)">
          <v-icon>mdi-delete</v-icon>
        </v-btn>
      </template>
      <template v-slot:expanded-item="{ headers, item }">
        <td :colspan="headers.length">
          <tags-table-item-details :item="item" class="mt-3 mb-3"/>
        </td>
      </template>
    </v-data-table>
    <confirm-dialog ref="confirm"/>
  </div>
</template>

<script>
import TagRegistration from './TagRegistration.vue';
import TagsService from './tags-service';
import TagsTableItemDetails from './TagsTableItemDetails.vue';

export default {
  props: {
    items: Array,
    search: String,
  },
  data() {
    return {
      headers: [
        {
          text: 'Name',
          value: 'nome',
        },
        {
          text: 'Description',
          value: 'descricao',
        },
        {
          text: 'Namespace',
          value: 'namespace',
        },
        {
          text: 'Startup',
          value: 'startup',
        },
        {
          text: 'Component',
          value: 'componente',
        },
        {
          text: 'Jira',
          value: 'jira',
          sortable: false,
        },
        {
          text: 'Monitoring',
          value: 'enabled',
        },
        {
          text: '',
          value: 'options',
          sortable: false,
        },
        { text: '', value: 'data-table-expand' },
      ],
    };
  },
  computed: {
    dependencias() {
      return (item) => item.dependencias.join('\n');
    },
    recursos() {
      return (item) => item.recursos.join('\n');
    },
  },
  methods: {
    remove(item) {
      TagsService.deleteTags({
        nome: item.nome,
      })
        .then(() => {
          this.$toast('Tag removed', { color: 'success' });
          this.emitChange();
        });
    },
    onClickDelete(item) {
      this.$refs.confirm.open('Você deseja remover a tag?',
        null,
        { width: 450 })
        .then((confirm) => {
          if (!confirm) {
            return;
          }
          this.remove(item);
        });
    },
    emitChange() {
      this.$emit('change');
    },
  },
  components: {
    TagsTableItemDetails,
    TagRegistration,
  },
};
</script>
