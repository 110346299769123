import axios from 'axios';

const getTags = (params) => axios.get('/tags', {
  params,
});

const postTags = (data) => axios.post('/tags', data);

const deleteTags = (data) => axios.delete('/tags', {
  data,
});

export default {
  getTags,
  postTags,
  deleteTags,
};
