<template>
  <v-card link
          :to="to"
          color="primary"
          min-height="120px"
          class="d-flex align-center justify-center"
          @click="onClickEvent">
    <v-card-text class="white--text headline">
      {{ title }}
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      required: true,
    },
    to: {
      type: String,
      required: true,
    },
  },
  methods: {
    onClickEvent(event) {
      this.$emit('click', event);
    },
  },
};
</script>
