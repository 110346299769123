import Vue from 'vue';
import VueRouter from 'vue-router';
import mainRouter from './main-router';
import loginRouter from './login';

Vue.use(VueRouter);

const routes = [
  loginRouter,
  mainRouter,
  {
    path: '*',
    redirect: { name: 'login' },
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

export default router;
