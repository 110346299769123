<template>
  <div class="login-container">
    <v-container fluid fill-height>
      <v-row
        align="center"
        justify="center"
      >
        <v-col cols="12" class="text-center">
          <img style="max-height: 150px" src="../../assets/icon1.png"
               alt="Logo"/>
          <div class="headline mt-1">
            Monitoramento
          </div>
        </v-col>
        <v-col cols="12" class="login-card">
          <div>
            <div>
              <div class="error--text"
                   v-if="error"
                   align="center">
                {{ error }}
              </div>
              <div>
                <v-text-field
                  solo
                  required
                  hide-details
                  class="append-container"
                  v-model="token"
                  label="Token"
                  v-on:keyup.enter="doLogin"
                  @click:append-outer="doLogin"
                  append-outer-icon="mdi-arrow-right-bold-circle-outline"
                >
                  <template v-slot:append-outer>
                    <div class="ml-1">
                      <v-btn icon
                             x-large
                             color="primary"
                             :disabled="!token"
                             :loading="loading"
                             @click="doLogin">
                        <v-icon x-large>
                          mdi-arrow-right-bold-circle-outline
                        </v-icon>
                      </v-btn>
                    </div>
                  </template>
                </v-text-field>
              </div>
            </div>
          </div>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import { SET_AUTH_INFO } from '../../store/auth/actions.type';
import TokenLoginService from './token-login-service';

const UNAUTHORIZED = 401;

export default {
  data() {
    return {
      loading: false,
      token: null,
      error: null,
    };
  },
  methods: {
    ...mapActions({
      setAuth: SET_AUTH_INFO,
    }),
    verifyToken(token) {
      // para verificar o token, tenta fazer a busca de applications
      return TokenLoginService.getApplications(token);
    },
    doLogin() {
      if (!this.token) {
        return;
      }
      this.loading = true;
      this.verifyToken(this.token)
        .then(() => {
          this.setAuth(this.token);
          this.$router.push({ name: 'main.home' });
        })
        .catch((err) => {
          if (err.response.status === UNAUTHORIZED) {
            this.error = 'Token inválido';
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
};
</script>

<style scoped>
.login-card {
  max-width: 600px;
}

.login-container {
  height: 100%;
}
</style>
