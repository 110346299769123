<template>
  <div>
    <div class="px-5">
      <v-row>
        <v-col cols="12">
          <span class="display-1">Applications</span>
        </v-col>
      </v-row>
    </div>
    <div class="mt-10">
      <applications-header v-model="searchValue" @refresh="refresh"/>

      <div v-if="loading">
        <circular-loading/>
      </div>

      <applications-table v-if="!loading && applications"
                          :search="searchValue"
                          :items="applications"
                          @change="refresh"/>
    </div>
  </div>
</template>

<script>
import { mapToList } from '../../utils/array-utils';
import ApplicationsService from './applications-service';
import ApplicationsTable from './ApplicationsTable.vue';
import ApplicationsHeader from './ApplicationsHeader.vue';

export default {
  data() {
    return {
      loading: false,
      searchValue: null,
      applications: null,
    };
  },
  methods: {
    refresh() {
      this.getApplications();
    },
    getApplications() {
      this.loading = true;
      ApplicationsService.getApplications()
        .then(({ data }) => {
          this.applications = mapToList(data);
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
  created() {
    this.getApplications();
  },
  components: {
    ApplicationsTable,
    ApplicationsHeader,
  },
};
</script>
