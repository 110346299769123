import Vue from 'vue';

const DEFAULT_MESSAGE = 'Aconteceu um erro interno no servidor';

const responseErrorInterceptor = (config) => {
  const { response } = config;
  if (response.status === 401) {
    return Promise.reject(config);
  }

  let message = DEFAULT_MESSAGE;

  if (response.data && response.data.message) {
    // eslint-disable-next-line prefer-destructuring
    message = response.data.message;
  }

  Vue.prototype.$toast(message, { color: 'error' });
  return Promise.reject(config);
};

export default responseErrorInterceptor;
