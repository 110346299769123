import store from '@/store';

import { INITIALIZE_FROM_COOKIES_AC } from '@/store/auth/actions.type';

import Main from '@/modules/main/Main.vue';
import Header from '@/modules/header/Header.vue';

import Home from '../modules/home/Home.vue';
import Applications from '../modules/applications/Applications.vue';
import Tags from '../modules/tags/Tags.vue';

const beforeEnterAuth = (to, from, next) => {
  if (!store.getters.isAuthenticated) {
    if (store.getters.tokenFromStorage) {
      store.dispatch(INITIALIZE_FROM_COOKIES_AC);
      return next();
    }
    return next('/login');
  }
  return next();
};

export default {
  path: '/',
  name: 'main',
  redirect: { name: 'main.home' },
  components: {
    default: Main,
    header: Header,
  },
  children: [
    {
      path: 'home',
      name: 'main.home',
      components: {
        default: Home,
      },
    },
    {
      path: 'applications',
      name: 'main.applications',
      components: {
        default: Applications,
      },
    },
    {
      path: 'tags',
      name: 'main.tags',
      components: {
        default: Tags,
      },
    },
  ],
  beforeEnter: beforeEnterAuth,
};
