import VueCookies from 'vue-cookies';
import { COOKIES_NAME } from './tokens.constants';

export default {
  token(state) {
    return state.token;
  },
  tokenHeader(state, getters) {
    const { token } = getters;
    if (!token) {
      return null;
    }
    return `Bearer ${token}`;
  },
  isAuthenticated(state) {
    return Boolean(state.token);
  },
  tokenFromStorage() {
    const token = VueCookies.get(COOKIES_NAME);
    if (!token) {
      return null;
    }
    return token;
  },
};
