<template>
  <lateral-dialog transition="fadet"
                    width="550"
                    v-model="dialog">
    <template v-slot:activator="{ on }">
      <slot name="activator" v-bind:on="on">
      </slot>
    </template>
    <template v-slot:title>
      Tag
    </template>
    <template>
      <div class="text--primary mt-3">
        <v-row dense>
          <v-col cols="12">
            <v-text-field
              outlined
              dense
              hide-details
              class="required"
              autocomplete="off"
              label="Nome"
              :disabled="editing"
              v-model="tag.nome"
              :error="$v.tag.nome.$error"
              @blur="$v.tag.nome.$touch()"
            />
          </v-col>
        </v-row>
        <v-row dense>
          <v-col cols="12">
            <v-text-field
              outlined
              dense
              hide-details
              class="required"
              autocomplete="off"
              label="Descrição"
              v-model="tag.descricao"
              :error="$v.tag.descricao.$error"
              @blur="$v.tag.descricao.$touch()"
            />
          </v-col>
        </v-row>
        <v-row dense>
          <v-col cols="12">
            <v-text-field
              dense
              outlined
              hide-details
              class="required"
              autocomplete="off"
              label="Namespace"
              v-model="tag.namespace"
              :error="$v.tag.namespace.$error"
              @blur="$v.tag.namespace.$touch()"
            />
          </v-col>
        </v-row>
        <v-row dense>
          <v-col cols="12">
            <v-text-field
              dense
              outlined
              hide-details
              class="required"
              autocomplete="off"
              label="Startup"
              v-model="tag.startup"
              :error="$v.tag.startup.$error"
              @blur="$v.tag.startup.$touch()"
            />
          </v-col>
        </v-row>
        <v-row dense>
          <v-col cols="12">
            <v-text-field
              dense
              outlined
              hide-details
              autocomplete="off"
              label="Componente"
              v-model="tag.componente"
            />
          </v-col>
        </v-row>
        <v-subheader class="mt-3">
          <v-icon class="pr-3">mdi-jira</v-icon>
          <span class="mr-1">Sistema Jira</span>
          <jira-info-tooltip/>
        </v-subheader>
        <v-row dense>
          <v-col cols="12" sm="6">
            <v-text-field
              dense
              outlined
              hide-details
              autocomplete="off"
              label="Valor"
              :error="$v.tag.sistema_jira.value.$error"
              @blur="$v.tag.sistema_jira.value.$touch()"
              v-model="tag.sistema_jira.value"
            />
          </v-col>
          <v-col cols="12" sm="6">
            <v-text-field
              dense
              outlined
              hide-details
              autocomplete="off"
              label="Id"
              :error="$v.tag.sistema_jira.id.$error"
              @blur="$v.tag.sistema_jira.id.$touch()"
              v-model="tag.sistema_jira.id"
            />
          </v-col>
        </v-row>
        <v-subheader class="mt-3">
          <v-icon class="pr-3">mdi-graph</v-icon>
          Dependências
          <v-btn icon class="ml-3" @click="addDependencia">
            <v-icon>
              mdi-plus
            </v-icon>
          </v-btn>
        </v-subheader>
        <v-row dense v-if="!dependencias.length">
          Sem dependências informadas
        </v-row>
        <v-row dense v-for="(dependencia, index) in dependencias"
               :key="`dep-${index}`">
          <v-col cols="12">
            <v-text-field
              dense
              outlined
              hide-details
              append-outer-icon="mdi-delete"
              @click:append-outer="removeDependencia(index)"
              class="required"
              autocomplete="off"
              label="Dependência"
              :error="$v.dependencias.$each[index].id.$error"
              @blur="$v.dependencias.$each[index].id.$touch()"
              v-model="dependencia.id"
            />
          </v-col>
        </v-row>
        <v-subheader class="mt-3">
          <v-icon class="pr-3">mdi-alert-circle</v-icon>
          Recursos
          <v-btn icon class="ml-3" @click="addRecurso">
            <v-icon>
              mdi-plus
            </v-icon>
          </v-btn>
        </v-subheader>
        <v-row dense v-if="!recursos.length">
          Sem recursos informados
        </v-row>
        <v-row dense v-for="(recurso, index) in recursos"
               :key="`res-${index}`">
          <v-col cols="12">
            <v-text-field
              dense
              outlined
              hide-details
              append-outer-icon="mdi-delete"
              @click:append-outer="removeRecurso(index)"
              class="required"
              autocomplete="off"
              label="Recurso"
              :error="$v.recursos.$each[index].id.$error"
              @blur="$v.recursos.$each[index].id.$touch()"
              v-model="recurso.id"
            />
          </v-col>
        </v-row>
        <v-row dense class="mt-3">
          <v-col cols="12">
            <v-switch
              hide-details
              v-model="tag.enabled"
              label="Monitoring"
            ></v-switch>
          </v-col>
        </v-row>
        <v-row dense>
          <v-col cols="12">
            <v-switch
              hide-details
              v-model="tag.funcionalidade"
              label="Funcionalidade"
            ></v-switch>
          </v-col>
        </v-row>
      </div>
    </template>
    <template v-slot:actions>
      <v-btn color="primary"
             :loading="loading"
             @click="save">
        Save
      </v-btn>
      <v-btn @click="close">
        Close
      </v-btn>
    </template>
  </lateral-dialog>
</template>

<script>
import { cloneDeep } from 'lodash';
import { required, requiredIf } from 'vuelidate/lib/validators';
import TagsService from './tags-service';

const initialState = () => ({
  nome: null,
  descricao: null,
  namespace: null,
  startup: null,
  dependencias: [],
  recursos: [],
  componente: null,
  enabled: true,
  funcionalidade: false,
  sistema_jira: {
    id: null,
    value: null,
  },
});

export default {
  props: {
    currentTag: Object,
  },
  data() {
    return {
      dialog: false,
      loading: false,
      editing: false,
      tag: initialState(),
      dependencias: [{ id: null }],
      recursos: [{ id: null }],
    };
  },
  methods: {
    addDependencia() {
      this.dependencias.push({ id: null });
    },
    removeDependencia(index) {
      this.dependencias.splice(index, 1);
    },
    addRecurso() {
      this.recursos.push({ id: null });
    },
    removeRecurso(index) {
      this.recursos.splice(index, 1);
    },
    close() {
      this.dialog = false;
    },
    isInvalid() {
      this.$v.$touch();
      return this.$v.$invalid;
    },
    buildTag() {
      const tag = { ...this.tag };
      tag.dependencias = this.dependencias.map((d) => d.id);
      tag.recursos = this.recursos.map((r) => r.id);

      if (!tag.sistema_jira.id || !tag.sistema_jira.value) {
        tag.sistema_jira = null;
      }

      return tag;
    },
    saveTag() {
      this.loading = true;
      TagsService.postTags(this.buildTag())
        .then(() => {
          this.$toast('Tag saved', { color: 'success' });
          this.close();
          this.$emit('change');
        })
        .finally(() => {
          this.loading = false;
        });
    },
    save() {
      if (this.isInvalid()) {
        return;
      }
      if (!this.dependencias.length) {
        this.$toast('Deve informar ao menos uma dependência', { color: 'error' });
        return;
      }
      this.saveTag();
    },
    buildInitialState() {
      this.tag = initialState();
      this.dependencias = [{ id: null }];
      this.recursos = [{ id: null }];
      this.$v.$reset();
    },
    buildEditing() {
      this.tag = cloneDeep(this.currentTag);
      if (!this.tag.sistema_jira) {
        this.tag.sistema_jira = {
          value: null,
          id: null,
        };
      }
      this.dependencias = this.tag.dependencias.map((d) => ({ id: d }));

      if (this.tag.recursos) {
        this.recursos = this.tag.recursos.map((d) => ({ id: d }));
      }
    },
  },
  watch: {
    dialog(newValue, oldValue) {
      if (newValue === oldValue) {
        return;
      }
      if (this.dialog) {
        this.buildInitialState();
      }
      if (this.editing) {
        this.buildEditing();
      }
    },
  },
  created() {
    this.editing = Boolean(this.currentTag);
  },
  validations: {
    dependencias: {
      $each: {
        id: {
          required,
        },
      },
    },
    recursos: {
      $each: {
        id: {
          required,
        },
      },
    },
    tag: {
      nome: {
        required,
      },
      descricao: {
        required,
      },
      namespace: {
        required,
      },
      startup: {
        required,
      },
      sistema_jira: {
        value: {
          required: requiredIf(function () {
            return this.tag.sistema_jira.id;
          }),
        },
        id: {
          required: requiredIf(function () {
            return this.tag.sistema_jira.value;
          }),
        },
      },
    },
  },
};
</script>
