var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.items,"item-key":"name","search":_vm.search},scopedSlots:_vm._u([{key:"item.url",fn:function(ref){
var item = ref.item;
return [_c('a',{attrs:{"href":item.url,"target":"_blank"}},[_vm._v(" "+_vm._s(item.url)+" ")])]}},{key:"item.monitoring",fn:function(ref){
var item = ref.item;
return [_c('enabled-disabled',{attrs:{"enabled":item.monitoring}})]}},{key:"item.options",fn:function(ref){
var item = ref.item;
return [_c('application-registration',{attrs:{"current-application":item},on:{"change":_vm.emitChange},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":""}},on),[_c('v-icon',[_vm._v("mdi-pencil")])],1)]}}],null,true)}),_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){return _vm.onClickDelete(item)}}},[_c('v-icon',[_vm._v("mdi-delete")])],1)]}}])}),_c('confirm-dialog',{ref:"confirm"})],1)}
var staticRenderFns = []

export { render, staticRenderFns }